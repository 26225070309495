<template>
  <v-dialog v-model="alterPasswordDialog" max-width="600" persistent>
    <v-card>
      <v-toolbar flat dark color="primary" max-height="65px">
        <v-toolbar-title> Alterar Senha </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-form ref="alterPasswordform">
          <v-text-field
            filled
            label="Senha Atual *"
            v-model="password.data.current_password"
            hide-details="auto"
            class="my-5 white white--text"
            color="primary"
            :append-icon="seePassword1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="seePassword1 = !seePassword1"
            :type="seePassword1 ? 'text' : 'password'"
            :rules="[$rules.required]"
            :disabled="password.loading"
          />

          <v-text-field
            filled
            label="Nova Senha *"
            v-model="password.data.new_password"
            hide-details="auto"
            class="my-5 white white--text"
            color="primary"
            :append-icon="seePassword2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="seePassword2 = !seePassword2"
            :type="seePassword2 ? 'text' : 'password'"
            :rules="[$rules.required, $rules.passwordMin]"
            :disabled="password.loading"
          />
          <v-text-field
            filled
            label="Confirmar Nova Senha *"
            v-model="password.data.new_password2"
            hide-details="auto"
            class="my-5 white white--text"
            color="primary"
            :append-icon="seePassword2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="seePassword2 = !seePassword2"
            :type="seePassword2 ? 'text' : 'password'"
            :rules="[$rules.required, $rules.passwordMin]"
            :disabled="password.loading"
          />
          <div align="end">
            <v-btn
              text
              @click="closeDialog()"
              :disabled="password.loading"
            >
              Cancelar
            </v-btn>
            <v-btn
              :disabled="password.loading"
              color="success"
              class="ml-2"
              :loading="password.loading"
              @click="alterPassword()"
              >Salvar</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlterPasswordDialog",
  data: () => ({
    alterPasswordDialog: false,
    seePassword1: false,
    seePassword2: false,
    readonly: false,
    password: {
      loading: false,
      data: {
        current_password: null,
        new_password: null,
      },
    },
    account: false,
  }),
  methods: {
    open(account) {
      this.alterPasswordDialog = true;
      this.account = account;
    },
    closeDialog() {
      this.alterPasswordDialog = false;
    },
    alterPassword() {
      if (this.$refs.alterPasswordform.validate()) {
        if (
          this.password.data.new_password == this.password.data.new_password2
        ) {
          this.password.loading = true;
          this.$axios
            .patch("/user", this.password.data)
            .then(() => {
              this.$showMessage("success", "Senha alterada");
              this.alterPasswordDialog = false;
              this.$refs.alterPasswordform.reset();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status == 400) {
                  this.$showMessage("warning", "Senha atual incorreta");
                  return;
                }
                if (error.response.status == 401) {
                  this.$showMessage(
                    "warning",
                    "A nova senha deve ser diferente da atual"
                  );
                  return;
                }
              }
              this.$showMessage(
                "error",
                "Erro inesperado na alteração de senha"
              );
            })
            .finally(() => {
              this.password.loading = false;
            });
        } else {
          this.$showMessage(
            "warning",
            "Os campos de nova senha, não correpondem."
          );
        }
      } else {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      }
    },
  },
};
</script>