<template>
  <v-sheet>
    <v-card class="d-flex ma-2" filled >
      <div class="primary white--text d-flex align-center">
        <h4 style="transform: rotate(-90deg)" class="text-uppercase ma-0 pa-0">
          Perfil
        </h4>
      </div>
      <div class="ma-2" style="width: 300px">
        <v-form ref="form">
          <v-text-field
            :rules="[$rules.required]"
            v-model="user.name"
            class="my-1"
            filled
            hide-details="auto"
            label="Nome *"
          />
          <v-text-field
            v-model="user.email"
            :rules="[$rules.required]"
            class="my-1"
            filled
            hide-details="auto"
            label="E-mail *"
          />
        </v-form>
        <div class="mt-3 d-flex">
          <v-btn
            class="mr-2"
            color="warning"
            @click="$refs.alterPassword.open(account)"
          >
            Alterar Senha</v-btn
          ><v-spacer />
          <v-btn class="success" dark @click="saveEditUser()">Salvar</v-btn>
        </div>
      </div>
      <div class="py-3">
        <v-divider class="mx-3 py-2" vertical />
      </div>
      <div class="ma-2" style="width: 300px">
        <v-form ref="formCompany">
        <v-text-field
          v-model="company.name"
          class="my-1"
          filled
          :rules="[$rules.required]"
          hide-details="auto"
          label="Razão Social *"
        />
        <v-text-field
          v-model="company.cnpj"
          class="my-1"
          filled
          hide-details="auto"
          label="CNPJ *"
          :rules="[$rules.required, $rules.cnpj]"
          v-mask="['##.###.###/####-##']"
        />
        </v-form>
        <div>
          <v-btn class="success mt-3" block @click="saveEditCompany()">Salvar</v-btn>
        </div>
      </div>
    </v-card>

    <v-card 
      v-if="$store.state.user.current_company.is_accountant"
      class="d-flex ma-2"
      filled
      height="200px"
    >
      <div  class="primary white--text d-flex align-center pt-12">
        <h4
          style="
            transform: rotate(-90deg);
            max-width: 50px;
            white-space: nowrap;
          "
          class="text-uppercase ma-0"
        >
          Assinaturas
        </h4>
      </div>
      <v-sheet width="100%" class="d-flex align-center px-3">
        <!--Conteúdo-->
        <v-btn
          color="success" large
          target="_blank" href="https://wa.me/554840420601"
        >
          <v-icon left>mdi-whatsapp</v-icon>
          Contatar equipe
        </v-btn>
        <!-- <v-card
          width="150px" height="150px" color="yellow darken-3" light 
          class="ma-2 pt-4 px-1 elevation-2 rounded-sm d-flex flex-column text-center justify-center">
          <v-icon size="35" color="white">mdi-crown</v-icon>
          <p class="m-0 white--text  pt-1">Assinatura 1</p>            
        </v-card> -->
      </v-sheet>
    </v-card>

    <AlterPasswordDialog ref="alterPassword" />
  </v-sheet>
</template>

<script>
import AlterPasswordDialog from "@/components/profile/AlterPasswordDialog.vue";
export default {
  name: "UserProfileView",

  components: {
    AlterPasswordDialog,
  },

  data: () => ({
    company: { name: "", cnpj: "" },
    account: false,
    user: { name: "", email: "" },
    loading: false,
    assinaturas: [],
  }),
  methods: {
    reload() {
      this.getuser();
      this.getCurrentCompany();
    },
    getuser() {
      this.loading = true;
      this.$axios
        .get("/user")
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          this.$showMessage("error", "Não foi possivel carregar os dados");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCurrentCompany() {
      this.company.name = this.$store.state.user.current_company.name;
      this.company.cnpj = this.$store.state.user.current_company.cnpj;
    },
    saveEditCompany(){
      if (!this.$refs.formCompany.validate()) {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      } else {
        this.loading = true;
        this.$axios
          .put("/company/", this.company)
          .then(() => {
            this.$showMessage("success", "Dados da empresa alterados"); 
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.$showMessage("error", "CNPJ ou CPF Inválido.");
                break;
              case 409:
                this.$showMessage("error", "CPF ou CNPJ já está em uso.");
                break;
              case 422:
                this.$showMessage("error", "Erro de validação dos dados.");
                break;
              default:
                this.$showMessage(
                  "error", "Erro inesperado na alteração dos dados");
                break;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    saveEditUser() {
      if (!this.$refs.form.validate()) {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      } else {
        this.loading = true;
        this.$axios
          .put("/user/", this.user)
          .then(() => {
            this.$showMessage("success", "Dados do usuario alterados");
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
                this.$showMessage("error", "Acesso não autorizado");
                break;
              case 404:
                this.$showMessage("error", "Usuário não encontrado");
                break;
              case 409:
                this.$showMessage("error", "Esse E-mail já foi cadastrado");
                break;
              default:
                this.$showMessage(
                  "error",
                  "Erro inesperado na alteração dos dados"
                );
                break;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.reload();
  },
};
</script>
